export const findContent = (
    data: [{ identifier: string }],
    identifier: string
) => {
    const newData = data.find((item) => {
        return item.identifier === identifier;
    });

    return newData;
};
