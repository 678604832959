import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Grid } from '../../lib/components/layout/grid';
import { SEO } from '../../lib/components/seo';
import { Heading } from '../../lib/components/typography/heading';
import { Paragraph } from '../../lib/components/typography/paragraph';
import { Box } from '../../lib/components/wrapper/box';
import { RichText } from '../../lib/ui/rich-text';
import { WithSidebarLayout } from '../../lib/ui/with-sidebar-layout';
import { findContent } from '../../lib/utils';
import { coverageAreasRoute } from '../../routes/coverage-areas';

const Summary = () => {
    const { pageContent } = useStaticQuery(query);

    const section: any = findContent(pageContent.content, 'summary-info');
    const text: any = findContent(section.content, 'summary-info-text');
    const partners: any = findContent(section.content, 'number-of-partners');
    const coveredHospitals: any = findContent(
        section.content,
        'number-of-hospitals-covered'
    );
    const image = section.images[0];

    return (
        <>
            <SEO
                title={pageContent.seo.title}
                description={pageContent.seo.description}
                image={pageContent.seo.image.file.url}
            />
            <WithSidebarLayout
                sidebar="coverage"
                sidebarNav={coverageAreasRoute}
                sidebarTitle="Coverage Areas"
                sidebarSticky={true}>
                <Grid
                    item
                    container
                    base={false}
                    className={clsx('l-sm:grid-cols-2 l-lg:grid-cols-45-55')}>
                    <Grid item>
                        <Heading tagName="h1" className="text-lg">
                            {text.title}
                        </Heading>
                        <RichText data={text.richText} />

                        <Grid
                            container
                            base={false}
                            defaultGap={false}
                            className={clsx('gap-2 mt-5', 'm-lg:grid-cols-2')}>
                            <Box className="grid items-center justify-items-center bg-light-gray">
                                <Paragraph
                                    className="text-lg leading-normal text-center"
                                    body={false}>
                                    {partners.total}
                                </Paragraph>
                                <Paragraph className="text-center">
                                    International & Local Partners
                                </Paragraph>
                            </Box>

                            <Box className="grid items-center justify-items-center bg-light-gray">
                                <Paragraph className="text-center">
                                    Serving
                                </Paragraph>
                                <Paragraph
                                    className="text-lg leading-normal text-center"
                                    body={false}>
                                    {coveredHospitals.total}
                                </Paragraph>
                                <Paragraph className="text-center">
                                    Hospitals
                                </Paragraph>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <img src={image.file.url} alt={image.description} />
                    </Grid>
                </Grid>
            </WithSidebarLayout>
        </>
    );
};

export default Summary;

const query = graphql`
    {
        pageContent: contentfulCoverageAreasPage(
            slug: { eq: "/coverage-areas/summary" }
        ) {
            content {
                ... on ContentfulComponentSection {
                    id
                    identifier
                    content {
                        ... on ContentfulComponentText {
                            id
                            identifier
                            title
                            richText {
                                raw
                            }
                        }
                        ... on ContentfulComponentCoverageNumbers {
                            id
                            identifier
                            total
                        }
                    }
                    images {
                        file {
                            url
                        }
                        description
                    }
                }
            }
            seo {
                title
                description
                image {
                    file {
                        url
                    }
                }
            }
        }
    }
`;
